export default {
    "types": {
        "plan": {
            "showName": "Постановка целей",
            "selected": false,
            "order": 1,
            "autostart": false,
            "canManageElementsBeforeDateStart": false,
            "canManageElementsAfterDateEnd": false,
            "hideDates": false
        },
        "correction": {
            "showName": "Корректировка целей",
            "order": 2,
            "canBeAddedDynamically": true,
            "hideOnCardCreate": true,
            "hideDates": true
        },
        "assessment": {
            "showName": "Подведение итогов",
            "selected": false,
            "order": 3,
            "canManageElementsBeforeDateStart": false,
            "canManageElementsAfterDateEnd": false,
            "hideDates": false
        }
    }
}