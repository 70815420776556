<template>


  <div class="comments-block">
    <div class="comments-block-messages" ref="comments-block-messages" :style="{'max-height': height}" v-loading="loading">
      <div
        v-if="!!comments.length"
        class="comments-block-messages-message"
        v-for="comment in comments"
        :key="comment.id"
      >
        <div class="comments-block-messages-message-title">
          <div style="font-style: italic">{{ comment.author.full_name }}</div>
          <div>
            <span style="font-style: italic">{{ new Date(comment.created_at).toLocaleString($companyConfiguration.helpers.getDateLocale()) }}</span>
            <el-button
	            v-if="$canAndRulesPass(comment, $permissions.COMMENT.UPDATE) && !disabled"
              style="margin-left: 10px"
              slot="reference"
              class="table-action-button"
              round
              size="mini"
              icon="fas fa-pen"
              :loading="loading || loadingFileUpload"
              @click="chooseUpdateComment(comment)"
            >
            </el-button>
            <el-popconfirm
              v-if="$canAndRulesPass(comment, $permissions.COMMENT.DELETE)"
              style="margin-left: 5px"
              confirm-button-text='Да'
              cancel-button-text='нет'
              icon="el-icon-question"
              title="Вы уверены, что хотите удалить сообщение?"
              @confirm="deleteComment(comment)"
            >
              <el-button
                slot="reference"
                class="table-action-button"
                type="default"
                round
                size="mini"
                icon="far fa-trash-alt"
                :loading="loading || loadingFileUpload"
              >
              </el-button>

            </el-popconfirm>
          </div>
        </div>
        <div class="comments-block-messages-message-body">
          {{ comment.text }}
          <div
            v-for="attachment in comment.attachments"
            :key="attachment.id"
            style="margin-top: 10px"
          >
            <a style="margin-right:5px" @click.prevent="downloadAttachment(attachment)"><i class="fas fa-download"></i></a>
            {{ attachment.name }}
          </div>
        </div>
      </div>
      <div v-if="!comments.length" align="center">
        Нет комментариев
      </div>
    </div>

    <el-row type="flex" v-if="!disabled">
      <div style="width:100%; margin-right:20px">
        <el-input
          v-model="messageText"
          type="textarea"
          :autosize="{ minRows: 2, maxRows: 6}"
          placeholder="Введите сообщение"
          size="mini"
          clearable
        >
        </el-input>
	      <div class="buttonBLock">
	        <el-button
	          :loading="loading"
	          :disabled="!messageText && (!$refs['file-uploader'] || !$refs['file-uploader'].uploadFiles.length)"
	          size="mini"
	          type="primary"
	          style="width:100%; margin-top:5px"
	          @click="actionComment"
	        >
	          {{ isEditComment ? 'РЕДАКТИРОВАТЬ' : sendButtonText }}
	        </el-button>
		      <el-button
			      v-if="isEditComment"
			      :loading="loading"
			      size="mini"
			      type="primary"
			      style="width:30%; margin-top:5px"
			      @click="cancelUpdateComment"
		      >
			      ОТМЕНИТЬ
		      </el-button>
	      </div>
      </div>
      <el-upload
        ref="file-uploader"
        class="chat-dragger"
        style="width: 260px;overflow: hidden"
        drag
        action=""
        multiple
        :auto-upload="false"
        :http-request="doFileUpload"
        :on-exceed="onExceed"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">Перетащите или нажмите для загрузки файла</div>
      </el-upload>
    </el-row>
  </div>


</template>

<script>

import {paginator} from "@/mixins/paginator";
import {downloader} from "@/mixins/downloader";

export default {
  name: 'comments-block',
  components: {},
  mixins: [paginator, downloader],

  props: {
    entityType: {type: String, required: true},
    entityId: {type: Number, required: true},
    entitySegment: {type: String, default: null},
    sendButtonText: {type: String, default: 'Отправить'},

    disabled: {type: Boolean, default: false},

    height: {
      type: String,
      default: '50vh'
    },
  },
  mounted() {
    this.getComments();
  },
  data() {
    return {
      loading: false,
      loadingFileUpload: false,
      comments: [],
      isEditComment: false,
      commentId: null,
      messageText: null,
      lastCreatedCommentId: null,
    }
  },
  watch: {},
  computed: {},
  methods: {
    scrollToBottom(){
      this.$nextTick(function () {
        if( this.$refs['comments-block-messages'] ){
          this.$refs['comments-block-messages'].scrollTop = this.$refs['comments-block-messages'].scrollHeight;
        }
      })
    },

    getComments() {
      this.loading = true;
      this.$api.comment.search({
        entity_type: this.entityType,
        entity_id: this.entityId,
        entity_segment: this.entitySegment,
        'expand': 'author, attachments, permissions',
      })
        .then(data => {
          this.comments = data.comments;
          this.scrollToBottom();
        })
        .finally(() => {
          this.loading = false;
        })
    },

    saveComment() {
      if (!this.messageText && !this.$refs["file-uploader"].uploadFiles.length) {
        return;
      }

      this.loading = true;
      this.$api.comment.create({
        entity_type: this.entityType,
        entity_id: this.entityId,
        entity_segment: this.entitySegment,
        text: this.messageText,
      })
        .then(data => {
          this.comments.push(data.comment);
          this.scrollToBottom();
          this.messageText = null;
          this.lastCreatedCommentId = data.comment.id;
          this.$refs["file-uploader"].submit();
          this.$emit('comment-added', data.comment);
        })
        .finally(() => {
          this.loading = false;
        })
    },
	  actionComment() {
		  this.isEditComment ? this.updateComment() : this.saveComment();
	  },
	  chooseUpdateComment(comment) {
	    this.messageText = comment.text;
	    this.commentId = comment.id;
	    this.isEditComment = true;
    },
	  cancelUpdateComment() {
		  this.messageText = null;
		  this.commentId = null;
		  this.isEditComment = false;
	  },
	  updateComment() {
		  if (!this.messageText) {
			  return;
		  }

		  this.loading = true;

		  this.$api.comment.update({
			  id: this.commentId,
			  text: this.messageText,
		  })
			  .then(data => {
				  this.$notify.success({
					  title: 'Сохранено',
					  message: 'Комментарий успешно сохранен'
				  });
				  this.cancelUpdateComment();
				  this.getComments();
			  })
			  .finally(() => {
				  this.loading = false;
			  })
	  },
    deleteComment(comment) {
      this.$api.comment.delete({
        id: comment.id,
      })
        .then(data => {
          this.$notify.success({
            title: 'Удалено',
            message: 'Комментарий успешно удален'
          });
          this.getComments();
        })
        .finally(() => {
        })
    },

    doFileUpload(params) {
      this.loadingFileUpload = true;
      let form = new FormData();
      form.append('comment_id', this.lastCreatedCommentId);
      form.append('attachment', params.file);
      return this.$api.comment.attach(form)
        .then(data => {
          this.$refs["file-uploader"].clearFiles();
          this.getComments()
        })
        .finally(()=>{
          this.loadingFileUpload = false;
        })
    },
    onExceed(params) {
      this.$notify.error({
        title: 'Лимит',
        message: 'Не более одного элемента'
      });
    },


    downloadAttachment(attachment) {
      this.downloadFile(
        false,
        this.$api.comment.downloadAttachment,
        { id: attachment.id }
      );
    }
  }
}

</script>

<style scoped lang="scss">
@import "@/assets/css/project-variables";
a {
  color: $--color-primary;
}

a:hover {
  cursor: pointer;
}

.comments-block {


  &-messages {
    max-height: 50vh;
    overflow: scroll;
    overflow-x: unset;
    overflow-y: auto;
    margin-bottom: 20px;

    &-message {
      padding: 10px 20px;
      /*background-color: #f1f1f1;*/
      background-color: #F5F7FA;
      margin: 10px 0;
      border: 1px solid #DDD;
      border-radius: $--border-radius-base;

      &-title {
        display: flex;
        justify-content: space-between;
        font-weight: bold;
      }

      &-body {
        white-space: pre-wrap;
      }
    }


  }
}


.comments-block .comments-block-messages .comments-block-message:not(:last-child) {
  margin-bottom: 20px;
}


</style>
<style lang="scss">
.chat-dragger .el-upload-dragger {
  height: 80px;
  width: 200px;
  padding: 5px 10px;
  border-radius: unset;
}

.comments-block .el-upload-dragger .el-icon-upload {
  font-size: 26px;
  margin: 0;
  line-height: unset;
}

.buttonBLock {
	display: flex;
}
</style>