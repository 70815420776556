export const sorter = {
    data() {
        return {
            sorter: {
                sort: null,
                props: [],
                param: 'sort'
            }
        }
    },
    methods: {
        setIsSortable(propName) {
            return this.sorter.props.includes(propName) ? 'custom' : false;
        },
        sorterFill(sorter) {
            this.sorter.props = sorter.props;
            this.sorter.param = sorter.param;
        },
        sortChanged(newSort, callback) {
            if (newSort.order === null) {
                this.sorter.sort = null;
            } else {
                this.sorter.sort = newSort.order == 'descending' ? ('-' + newSort.prop) : newSort.prop;
            }

            if (typeof callback === 'function') {
                callback();
            }
        },

    }
}