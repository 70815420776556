<template>
  <el-container id="app">
    <el-header style="height: 41px; border-bottom: solid 1px #e6e6e6;">
      <el-row type="flex" justify="space-between">
        <div>
          <span style="margin-right:10px">
            <router-link :to="{name: 'Home'}">
              <img alt="Главная" style="padding-bottom: 11px" height="25" src="@/assets/img/logo.png"/>
            </router-link>
          </span>


          <menu-component></menu-component>

        </div>
        <div>

          <actions-component></actions-component>

        </div>
      </el-row>
    </el-header>
    <el-main v-if="user.id">
      <router-view v-if="user.roles.length" />
      <user-no-role-overlay v-if="!user.roles.length" />
    </el-main>
<!--    <div class="version-div">-->
<!--      version: {{version}}-->
<!--    </div>-->


  </el-container>

</template>

<script>
import {mapGetters} from 'vuex';
import MenuComponent from "@/components/header/MenuComponent.vue";
import ActionsComponent from "@/components/header/ActionsComponent.vue";
import UserNoRoleOverlay from "@/components/user/UserNoRoleOverlay.vue";

export default {
  name: "default-layout",
  components: {UserNoRoleOverlay, ActionsComponent, MenuComponent},

  computed: {
    ...mapGetters(['user']),
    layout() {
      return this.$route.meta.layout || 'default-layout'
    }
  },

  data() {
    return {
      version: process.env.PACKAGE_VERSION,
    }
  },

  created() {
    this.$store.dispatch('fetchUser');
    this.$store.dispatch('settings/loadSettings');
  },

  mounted() {
    // console.log(process.env);
    // console.log(this.$config);
  },
  methods: {}

}
</script>

<style>

.version-div{
  //margin-top: auto;
  //text-align: right;
  position: absolute;
  top:0;
  right: 0;
  font-size: 14px;
  color: #c7c7c7;

}

.el-avatar > img {
  display: unset;
  height: unset;
  width: 100%;
}

</style>