<template>
  <div>


    <el-upload
      class="import-modal-upload"
      :class="{tight: tight}"
      v-loading="loading"
      :accept="accept"
      ref="file-uploader"
      drag
      action=""
      :limit="2"
      :file-list="fileList"
      :on-change="changeFileList"
      :auto-upload="true"
      :show-file-list="false"
      :http-request="doFileUpload"
    >
      <i class="el-icon-upload"></i>
      <div class="el-upload__text-block">
        <div class="el-upload__text" v-if="text" v-html="text"></div>
        <div class="el-upload__tip" v-if="tip" v-html="tip"></div>
      </div>


      <!--        <el-button size="small" type="primary">Выберите файл для загрузки</el-button>-->
    </el-upload>

  </div>
</template>

<script>

export default {
  name: "import-upload",
  mixins: [],
  components: {},
  props: {
    tight: {type: Boolean, default: false},

    entity: {type: String, required: true},
    text: {type: String, default:'Перетащите или <em>нажмите для загрузки файла</em>'},
    tip: {type: String},

    accept: {type: String},

    data: {},
  },

  data() {
    return {
      loading: false,

      fileList: [],
      errors: [],
    }
  },

  watch: {},
  methods: {

    changeFileList() {
      this.fileList = this.$refs["file-uploader"].uploadFiles
      if (this.fileList.length === 2) this.fileList.splice(0, 1)
    },


    doFileUpload() {
      this.loading = true;

      let form = new FormData();
      form.append('file', this.fileList[0].raw);
      form.append('entity', this.entity);
      if (this.data) {
        form.append('data', JSON.stringify(this.data));
      }

      return this.$api.exportImport.import(form)
        .then(data => {

          this.errors = data.errors;
          if (this.errors.length) {
            this.$emit('errors', this.errors);
          }

          if (!this.errors.length) {
            this.$emit('import-succeed', data.response);
            if (this.closeOnSuccess) {
              this.show = false;
            }
          }

          if (data.response) {
            this.$emit('on-response', data.response);
          }

        })
        .finally(() => {
          this.loading = false;
          this.$emit('import-finished');
        })
    },


  },


}
</script>

<style lang="scss">
.import-modal-upload {
  width: 100%;

  & .el-upload {
    width: 100%;
    //height: 100%;

    & .el-upload-dragger {
      padding: 5px;
      width: 100%;
    }
  }


  &.tight {
    & .el-upload-dragger {
      height: unset;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    & .el-upload__text-block{
      display: inline-block;
      margin-left: 10px;
    }
    & .el-icon-upload {
      margin: unset;
      font-size: 45px;
      line-height: unset;
    }
  }


}
</style>