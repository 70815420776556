<template>
  <div>

    <component
      v-if="actionsTemplate"
      :is="actionsTemplate"
    ></component>


    <el-row v-else type="flex">
      <!--            <feedback-dialog>-->
      <!--              <el-tooltip slot="reference" content="Форма обратной связи" placement="top-start">-->
      <!--                <el-button-->
      <!--                  type="text"-->
      <!--                  icon="far fa-question-circle"-->
      <!--                >-->
      <!--                </el-button>-->
      <!--              </el-tooltip>-->

      <!--            </feedback-dialog>-->
      <notifications
        style="margin-left: 15px"
        :user-id="user.id"
      ></notifications>

      <!--            <el-avatar-->
      <!--              style="margin-right: 10px"-->
      <!--              fit="scale-down"-->
      <!--              shape="circle"-->
      <!--              :size="35"-->
      <!--              :src="'http://evraz.dev.knuateam.ru/data/avatars'+user.avatar"-->
      <!--            >-->
      <!--              <img src="@/assets/img/no_user_photo_2.jpg"/>-->
      <!--            </el-avatar>-->


      <div style="margin-right: 10px; padding-top:9px">
        <div>{{ user.last_name }} {{ user.first_name }}</div>
        <!--                            <div style="color:#fab82e">{{ user.position }}</div>-->
      </div>

      <!--                        <add-users-dialog-->
      <!--                            style="padding-top:6px"-->
      <!--                            no-division-->
      <!--                            filter-role="user"-->
      <!--                            add-button-text="Выбрать отмеченного"-->
      <!--                            @users-selected="activeUserSelected"-->
      <!--                        >-->
      <!--                            <el-button-->
      <!--                                slot="reference"-->
      <!--                                size="mini"-->
      <!--                                type="text"-->
      <!--                                icon="fas fa-person-booth"-->
      <!--                            >-->

      <!--                            </el-button>-->
      <!--                        </add-users-dialog>-->


      <el-tooltip content="Выход" placement="top-start">
        <el-button
          size="mini"
          type="text"
          icon="fas fa-sign-out-alt"
          @click="logout"
        >
        </el-button>
      </el-tooltip>


      <deputy-modal
        v-if="$canAndRulesPass(user, $permissions.USER.DEPUTIES_LOGIN)"
        style="margin-top: 1px;"
      ></deputy-modal>
    </el-row>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import Notifications from "@/components/Notifications.vue";
import router from "@/router";
import DeputyModal from "@/auth/DeputyModal.vue";

export default {
  name: "actions-component",
  components: {DeputyModal, Notifications},

  props: {},

  watch: {},
  computed: {
    ...mapGetters(['user']),
    actionsTemplate() {
      return this.$companyConfiguration.settings.getActionsTemplate();
    },
  },
  data() {
    return {

    }
  },
  mounted() {
  },
  methods: {
    activeUserSelected(selection) {
      localStorage.userId = selection[0].id;
      this.$router.go();
    },

    logout() {
      this.$store.dispatch('logoutUser');
      router.push({ name: 'Login'});
    },
  }
}
</script>


<style lang="scss">

</style>