export default {
    "types": {
        "library": {
            "showName": "Библиотека целей"
        },
        "library_manual_fact": {
            "showName": "Библиотека целей. Ручной факт"
        },
        "multiplier": {
            "showName": "Библиотека коэффициентов"
        }
    },
    "use_scales_units_library": true,
    "element_change_propagation_mode": 2,
    "folder_moderators_config_enabled": false,
    "folder_visibility_config_enabled": true,
    "folder_visibility_config_mode": 1,
    "folder_visibility_config_see_child": true,
    "on_import_using_visible": true,
    "on_folder_clone_clear_fields": [
        "bonus",
        "scale.fact"
    ],
    "elements_table_columns": [
        {
            "label": "ID",
            "prop": "id",
            "width": 70
        },
        {
            "label": "Наименование цели",
            "prop": "name",
            "template": "components/tableColumnTemplates/library/ElementNameTableColumn",
            "bind": {
                "scope": "scope",
                "show-folders-in-search": "templateData.$parent.$parent.$data.showFoldersInSearch"
            }
        },
        {
            "label": "Шкала",
            "prop": "scale.template.name",
            "bind": {
                "scope": "scope"
            },
            "width": 350
        },
        {
            "label": "Единица измерения",
            "prop": "scale.unit.name",
            "bind": {
                "scope": "scope"
            },
            "width": 150
        },
        {
            "label": "План",
            "width": 140,
            "template": "components/configurableScales/tableColumnTemplates/ElementConfigurableScalePlanTableColumn",
            "bind": {
                "scope": "scope"
            }
        },
        {
            "label": "Факт",
            "width": 240,
            "template": "components/configurableScales/tableColumnTemplates/ElementConfigurableScaleFactTableColumn",
            "bind": {
                "scope": "scope"
            }
        }
    ]
}