<template>
  <div>
    <div
      class="click-to-edit-div"
      :class="{'click-to-edit-enabled': (!disabled && !edit)}"
      @click="startEdit"
    >
      <el-input
        v-model="valueLocal"
        :size="size"
        ref="inp"
        v-if="edit && type=='input'"
        v-number-format="numberFormat ? numberFormat : false"
        :show-word-limit="showWordLimit"
        :maxlength="wordLimit ? wordLimit : null"
        @blur="stopEdit"
        @keydown.enter.native="enterPressed"
        @keyup.esc.native="valueLocal = value; edit = false;"
      ></el-input>
      <el-input
        v-if="edit && type=='textarea'"
        v-model="valueLocal"
        style="z-index: 9"
        type="textarea"
        autosize
        :size="size"
        ref="inp"
        :show-word-limit="showWordLimit"
        :maxlength="wordLimit ? wordLimit : null"
        @blur="stopEdit"
        @keydown.enter.native="enterPressed"
        @keyup.esc.native="valueLocal = value; edit = false;"
      ></el-input>
      <el-select
        v-model="valueLocal"
        :placeholder="placeholder ? placeholder : 'Выберите'"
        :size="size"
        :filterable="selectFilterable"
        ref="inp"
        v-if="edit && type=='select'"
        @change="$emit('change', $event); stopEdit()"
        @visible-change="$event ? $emit('opened') : stopEdit()"
        @keyup.esc.native="valueLocal = value; edit = false;"
      >
        <el-option
          v-for="unit in selectOptions"
          :key="unit[selectOptionId]"
          :class="{'option-no-label': !unit[selectOptionName]}"
          :label="unit[selectOptionName] ? unit[selectOptionName] : selectOptionEmptyNameText"
          :value="selectCastValueToInt ? parseInt(unit[selectOptionId]) : unit[selectOptionId]">
        </el-option>
      </el-select>
      <el-date-picker
        v-if="edit && (type=='date-range-picker' || type=='date-picker')"
        v-model="valueLocal"
        style="width: 150px"
        :picker-options="datePickerOptions"
        :size="size"
        :clearable="clearable"
        ref="inp"
        :type="type=='date-range-picker' ? 'daterange' : 'date'"
        value-format="yyyy-MM-dd"
        format="dd.MM.yyyy"
        range-separator="-"
        start-placeholder="Дата начала"
        end-placeholder="Дата окончания"
        @change="stopEdit"
        @blur="edit = false;"
        @keyup.esc.native="valueLocal = value; edit = false;"
      >
      </el-date-picker>


      <div v-if="!edit && (type=='input' || type=='modal' || type=='textarea')">
        <span v-if="numberFormat">{{ valueLocal | numberFormat(numberFormat) }}</span>
        <span v-if="!numberFormat" style="white-space: pre-line">{{ valueLocal }}</span>

        <span class="placeholder" v-if="placeholder && !valueLocal">{{ placeholder }}</span>
      </div>

      <span v-if="!edit && type=='select'">
        {{ getSelectOptionName() }}
        <span class="placeholder" v-if="placeholder && !valueLocal && valueLocal !== 0 && valueLocal !== 0.0">{{ placeholder }}</span>
      </span>

      <span v-if="!edit && type=='date-range-picker'">
        {{ new Date(valueLocal[0]).toLocaleDateString($companyConfiguration.helpers.getDateLocale()) }} - {{ new Date(valueLocal[1]).toLocaleDateString($companyConfiguration.helpers.getDateLocale()) }}
      </span>
      <span v-if="!edit && type=='date-picker'" style="display: inline-block; padding-top:2px">
        <span v-if="valueLocal">{{ (new Date(valueLocal)).toLocaleDateString($companyConfiguration.helpers.getDateLocale()) }}</span>
        <span class="placeholder" v-if="placeholder && !disabled && !valueLocal">{{ placeholder }}</span>
      </span>


      <span ref="wrapper" v-if="type=='button-modal'">
        <slot name="reference"></slot>
      </span>
    </div>
    <el-dialog
      v-if="type=='button-modal' || type=='modal'"
      :title="modalTitle"
      :visible.sync="edit"
      append-to-body
      :close-on-click-modal="false"
    >
      <el-input
        class="click-to-edit-textarea"
        :disabled="disabled"
        type="textarea"
        :autosize="{ minRows: 4}"
        :placeholder="placeholder"
        v-model="valueLocal"
        :show-word-limit="showWordLimit"
        :maxlength="wordLimit ? wordLimit : null"
      >
      </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="edit = false;">Закрыть</el-button>
        <el-button v-if="!disabled" type="primary" size="small" @click="stopEdit">Сохранить</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "click-to-edit",
  components: {},

  props: {
    value: {},
    disabled: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'input',
      validator: function (value) {
        return ['input', 'textarea', 'select', 'date-picker', 'date-range-picker', 'modal', 'button-modal'].indexOf(value) !== -1
      }
    },
    size: {
      type: String,
      default: 'mini',
      validator: function (value) {
        return ['', 'medium', 'small', 'mini'].indexOf(value) !== -1
      }
    },
    selectOptions: {
      type: Array,
      default: function () {
        return []
      }
    },
    selectOptionId: {
      type: String,
      default: 'id'
    },
    selectOptionName: {
      type: String,
      default: 'name'
    },
    selectOptionEmptyNameText: {
      type: String,
      default: ''
    },
    selectCastValueToInt:{
      type: Boolean,
      default: false
    },
    selectFilterable: {type: Boolean, default: false},
    placeholder: {
      type: String,
      default: 'Нажмите для редактирования'
    },
    digitsOnly: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },

    wordLimit: {
      type: Number,
      default: 0
    },
    showWordLimit: {
      type: Boolean,
      default: false
    },

    numberFormat: {type: Object},

    datePickerOptions: {
      type: Object,
      default: ()=>{
        return {firstDayOfWeek: 1}
      },
    },

    modalTitle: {
      type: String,
      default: 'Редактирование'
    }
  },

  data() {
    return {
      edit: false,
      valueLocal: this.value,

      stoppedEdit: false,
    }
  },
  mounted() {

  },
  watch: {
    value: function () {
      this.valueLocal = this.value;
    },
    valueLocal: function () {
      if (this.digitsOnly) {
        this.valueLocal = this.valueLocal
          ? this.valueLocal
            .toString()
            .replace(",", ".")
            .replace(/[^0-9.-]/g, '')
          : ((this.valueLocal === "0" || this.valueLocal === 0) ? 0 : null);
      }
    }
  },
  methods: {
    startEdit() {
      if (this.disabled && this.type != 'button-modal' && this.type != 'modal') {
        return;
      }
      this.edit = true;
      this.stoppedEdit = false;
      if (this.type != 'button-modal' && this.type != 'modal') {
        this.$nextTick(function () {
          this.$refs.inp.focus();
        })
      }
    },
    enterPressed(event){
      if( !event.shiftKey && !event.ctrlKey && !event.altKey ){
        this.stopEdit(event);
      }
    },
    stopEdit(event) {
      this.edit = false;
      if (this.valueLocal !== this.value && !this.stoppedEdit) {
        this.$emit('input', this.valueLocal);
        this.stoppedEdit = false;
      }
    },
    getSelectOptionName() {
      if (!this.valueLocal && this.valueLocal !== 0 && this.valueLocal !== 0.0) {
        return '';
      }
      let option = this.selectOptions.find(option => option[this.selectOptionId] == this.valueLocal);
      if (!option) {
        return '';
      }
      return option[this.selectOptionName];
    }
  },

}
</script>

<style scoped lang="scss">
@import "./src/assets/css/project-variables";

.click-to-edit-div {
  min-height: 22px;
}

.click-to-edit-div.click-to-edit-enabled:hover {
  cursor: pointer;
}

.click-to-edit-enabled {
  //background-color: #f0f7ff;
  border: $--editable-border;
  border-radius: $--border-radius-base;
  padding: 1px 5px;
}

.click-to-edit-div .placeholder {
  color: #afafaf;
  font-style: italic;
}


</style>

<style>
.click-to-edit-textarea textarea[disabled] {
  color: black !important;
}
</style>