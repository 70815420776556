import requestSender from "./base/requestSender";

export default function(axiosInstance){
    return {
        search(payload){
            return requestSender('get', 'user/search', payload);
        },
        searchUniqueUsersPropValues(payload){
            return requestSender('get', 'user/search-unique-users-prop-values', payload);
        },
        getAuthUser(payload){
            return requestSender('get', 'user/auth-user-data', payload);
        },
        selectionByExcel(payload){
            return requestSender('post', 'user/selection-by-excel', payload);
        },

        searchDeputies(payload){
            return requestSender('get', 'user-deputy/search', payload);
        },
        searchAvailableMeDeputies(payload){
            return requestSender('get', 'user-deputy/search-me-available', payload);
        },
        createDeputy(payload){
            return requestSender('post', 'user-deputy/create', payload);
        },
        deleteDeputy(payload){
            return requestSender('post', 'user-deputy/delete', payload);
        },
        loginAsDeputy(payload){
            return requestSender('post', 'user-deputy/login-as-deputy', payload);
        },

    }
}