export default {
    "matrix_columns": [
        {
            "label": "Наименование цели",
            "minWidth": 200,
            "template": "components/tableColumnTemplates/card/ElementNameTableColumn",
            "bind": {
                "scope": "scope",
                "is-matrix": true
            }
        }
    ]
}