<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="show"
      width="30%"
      append-to-body
      :close-on-click-modal="false"
    >

      <template v-slot:title>
        <div style="display: flex; justify-content: space-between">
          <div>{{title}}</div>

          <el-button
            v-if="!hideExample"
            style="margin-right: 50px; margin-top: -4px;"
            type="text"
            size="mini"
            icon="fas fa-file-excel"
            @click="getImportExample"
          >
            Скачать пример
          </el-button>
        </div>
      </template>


      <import-upload
        :entity="entity"
        :tip="tip"
        :accept="accept"
        :data="data"
        @import-succeed="importSucceed"
        @on-response="$emit('on-response', $event)"
        @import-finished="$emit('import-finished', $event)"
        @errors="parseErrors"
      ></import-upload>


      <!--      <el-upload-->
      <!--        class="import-modal-upload"-->
      <!--        v-loading="loading"-->
      <!--        accept="image/png"-->
      <!--        ref="file-uploader"-->
      <!--        drag-->
      <!--        action=""-->
      <!--        :limit="2"-->
      <!--        :file-list="fileList"-->
      <!--        :on-change="changeFileList"-->
      <!--        :auto-upload="true"-->
      <!--        :show-file-list="false"-->
      <!--        :http-request="doFileUpload"-->
      <!--      >-->
      <!--        <i class="el-icon-upload"></i>-->
      <!--        <div class="el-upload__text">Перетащите или <em>нажмите для загрузки файла</em></div>-->
      <!--        <div class="el-upload__tip" v-if="tip">{{tip}}</div>-->

      <!--&lt;!&ndash;        <el-button size="small" type="primary">Выберите файл для загрузки</el-button>&ndash;&gt;-->
      <!--      </el-upload>-->

      <div
        v-if="errors.length"
        style="margin-top: 30px"
      >
        Ошибки:
        <div v-for="error in errors">{{error}}</div>
      </div>




      <!--      <div slot="footer" class="dialog-footer">-->
      <!--        <el-button size="small" @click="show = false">Закрыть</el-button>-->
      <!--      </div>-->
    </el-dialog>

    <span ref="wrapper">
      <slot name="reference"></slot>
    </span>
  </div>
</template>

<script>

import {off, on} from "element-ui/src/utils/dom";
import {downloader} from "@/mixins/downloader";
import ImportUpload from "@/components/exportImport/ImportUpload.vue";

export default {
  name: "import-modal",
  mixins: [downloader],
  components: {ImportUpload},
  props: {
    title: {type: String, default: 'Импорт'},

    closeOnSuccess: {type: Boolean, default: false},
    hideExample: {type: Boolean, default: false},

    entity: {type: String, required: true},
    tip: {type: String},
    accept: {type: String},
    data: {},
  },

  data() {
    return {
      show: false,
      loading: false,

      fileList: [],

      errors: [],
    }
  },
  mounted() {
    if (this.$refs.wrapper.children) {
      on(this.$refs.wrapper.children[0], 'click', this.showModal);
    }
  },
  watch: {},
  methods: {
    showModal() {
      this.show = true;
    },


    getImportExample() {
      this.downloadFile(
        false,
        this.$api.exportImport.getImportExample,
        {entity: this.entity}
      )
    },

    importSucceed(response){
      this.$emit('import-succeed', response);
      if( this.closeOnSuccess ){
        this.show = false;
      }
    },

    parseErrors(errors){
      this.errors = errors;
    },


    // changeFileList() {
    //   this.fileList = this.$refs["file-uploader"].uploadFiles
    //   if(this.fileList.length === 2) this.fileList.splice(0, 1)
    // },
    //
    //
    // doFileUpload() {
    //   this.loading = true;
    //
    //   let form = new FormData();
    //   form.append('file', this.fileList[0].raw);
    //   form.append('entity', this.entity);
    //   if( this.data ){
    //     form.append('data', JSON.stringify(this.data));
    //   }
    //
    //   return this.$api.exportImport.import(form)
    //     .then(data => {
    //
    //       this.errors = data.errors;
    //
    //       if( !this.errors.length ){
    //         this.$emit('import-succeed', data.response);
    //         if( this.closeOnSuccess ){
    //           this.show = false;
    //         }
    //       }
    //
    //       if( data.response ){
    //         this.$emit('on-response', data.response);
    //       }
    //
    //     })
    //     .finally(() => {
    //       this.loading = false;
    //       this.$emit('import-finished');
    //     })
    // },




  },

  beforeDestroy() {
    if (this.$refs.wrapper.children) {
      off(this.$refs.wrapper.children[0], 'click', this.showModal);
    }
  }

}
</script>

<style lang="scss">

</style>