<template>
  <div
    class="card-stage-wrapper"
    :class="{
      active: active,
    }"
  >
    <div class="card-stage-header">
      <div class="card-stage-header-name">
        <div v-if="showStageName" style="display: inline-block">{{ stageName }}</div>
        <div
          v-show="!hideStageDates"
          style="display: inline-block; margin-left:30px; font-weight: normal"
          class="card-stage-header-dates"
        >
          <click-to-edit
            v-model="stage.date_start"
            :disabled="!$canAndRulesPass(stage, $permissions.CARD_STAGE.SET_DATE)"
            placeholder="Выберите дату начала"
            style="display: inline-block"
            type="date-picker"
            :date-picker-options="{
              firstDayOfWeek: 1,
              disabledDate(time){
                if( !stage.date_end ) return false;
                return time > new Date(stage.date_end);
              }
            }"
            @input="$emit('stage-dates-changed', stage)"
          ></click-to-edit>
           -
          <click-to-edit
            v-model="stage.date_end"
            :disabled="!$canAndRulesPass(stage, $permissions.CARD_STAGE.SET_DATE)"
            placeholder="Выберите дату окончания"
            style="display: inline-block"
            type="date-picker"
            :date-picker-options="{
              firstDayOfWeek: 1,
              disabledDate(time){
                if( !stage.date_start ) return false;
                return time < new Date(stage.date_start);
              }
            }"
            @input="$emit('stage-dates-changed', stage)"
          ></click-to-edit>
        </div>
      </div>
      <div>
        <el-button
          v-if="$canAndRulesPass(stage, $permissions.CARD_STAGE_NEGOTIATION.CREATE)"
          class="table-action-button"
          round
          icon="fas fa-plus"
          @click="$refs.negotiationEditDialog.open()"
        >
<!--          <span v-if="hideStageDates">&nbsp;Добавить этап&nbsp;</span>-->
        </el-button>

        <el-popconfirm
          v-if="$canAndRulesPass(stage, $permissions.CARD_STAGE.DELETE)"
          style="margin-left:10px;"
          confirm-button-text='Да'
          cancel-button-text='нет'
          icon="el-icon-question"
          title="Вы уверены, что хотите удалить этап?"
          @confirm="$emit('stage-deleted', stage)"
        >
          <el-button
            slot="reference"
            class="table-action-button"
            round
            icon="far fa-trash-alt"
          ></el-button>
        </el-popconfirm>


      </div>
    </div>

    <el-divider class="card-stage-divider"></el-divider>


    <Container
      orientation="horizontal"
      behaviour="contain"
      drag-handle-selector=".negotiation-actions-drag-handle"
      @drop="onDrop($event)"
      tag="ul"
      ref="stage_ul"
      style="display: flex"
      class="timeline"
      :class="{oneNegotiation: stage.negotiations.length === 1}"
    >
      <Draggable
        v-for="(negotiation, negotiationIndex) in stage.negotiations"
        :key="negotiationIndex"
        :ref="'negotiation_'+(stage.type)+'_'+negotiationIndex"
        tag="li"
        class="negotiation"
        :class="{
          complete: negotiation.negotiated,
          active: isNegotiationActive(negotiation),
        }"
      >
          <div class="negotiation-actions">
            <el-button
              v-if="$canAndRulesPass(negotiation, $permissions.CARD_STAGE_NEGOTIATION.MOVE)"
              class="negotiation-actions-drag-handle negotiation-edit-button"
              type="text"
              size="mini"
              icon="fas fa-arrows-alt-h"
            >
            </el-button>

            <el-popconfirm
              v-if="$canAndRulesPass(negotiation, $permissions.CARD_STAGE_NEGOTIATION.SET_AS_CURRENT)"
              confirm-button-text='Да'
              cancel-button-text='нет'
              icon="el-icon-question"
              title="Отправить пользователю уведомление?"
              @confirm="$emit('negotiation-selected', {negotiation, sendNotify: true})"
              @cancel="$emit('negotiation-selected', {negotiation, sendNotify: false})"
            >
              <el-button
                class="negotiation-edit-button"
                slot="reference"
                :disabled="stage.current_negotiation_id === negotiation.id"
                type="text"
                size="mini"
                icon="fas fa-map-marker-alt"
              >
              </el-button>
            </el-popconfirm>
            <el-button
              v-if="$canAndRulesPass(negotiation, $permissions.CARD_STAGE_NEGOTIATION.UPDATE)"
              class="negotiation-edit-button"
              type="text"
              size="mini"
              icon="fas fa-pen"
              @click="$refs.negotiationEditDialog.open(negotiation)"
            >
            </el-button>
            <el-popconfirm
              v-if="$canAndRulesPass(negotiation, $permissions.CARD_STAGE_NEGOTIATION.DELETE)"
              confirm-button-text='Да'
              cancel-button-text='нет'
              icon="el-icon-question"
              title="Вы уверены, что хотите удалить согласование?"
              @confirm="$emit('negotiation-deleted', {negotiationIndex, negotiation})"
            >
              <el-button
                class="negotiation-delete-button"
                slot="reference"
                type="text"
                size="mini"
                icon="far fa-trash-alt"
              >
              </el-button>
            </el-popconfirm>
          </div>



          <div class="negotiation-bottom">
            <div class="negotiation-bottom-icon">
              <i v-if="negotiation.negotiated" class="el-icon-check"></i>
              <i v-else-if="isNegotiationActive(negotiation)" class="fas fa-clock"></i>
              <i v-else class="fas fa-circle"></i>
            </div>
            <div class="negotiation-bottom-type">
              <div v-if="negotiation.type === 'other'">{{ negotiation.description }}</div>
              <div v-else>{{ $companyConfiguration.cardStageNegotiations.getNegotiationNameByType(negotiation.type) }}</div>
            </div>
            <div>{{ negotiation.user ? negotiation.user.full_name : '&nbsp;' }}</div>
          </div>
      </Draggable>
    </Container>

    <negotiation-edit-dialog
      ref="negotiationEditDialog"
      @negotiation-saved="saveNegotiation"
    ></negotiation-edit-dialog>
  </div>

</template>

<script>
import {Container, Draggable} from 'vue-dndrop';
import {applyDragForDndrop} from "@/utils/helpers";
import ClickToEdit from "@/components/ClickToEdit";
import NegotiationEditDialog from "@/components/cards/CardStagesBlock/NegotiationEditDialog";
import {mapGetters} from "vuex";


export default {
  name: "stage-item-new",
  components: {NegotiationEditDialog, ClickToEdit, Container, Draggable},

  props: {
    showStageName: {type: Boolean, default: true},
    hideStageDates: {type: Boolean, default: false},
    stage: {type: Object, required: true},
    active: {type: Boolean, default: false},
  },

  computed: {
    stageName() {
      const nameFromSettings = this.$companyConfiguration.cardStages.getStageShowName(this.stage.type);
      return nameFromSettings ? nameFromSettings : this.stage.type;
    },
  },
  data() {
    return {}
  },
  mounted() {
    this.scrollToActiveNegotiation();
  },
  created() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'card/stages/updateStages') {
        this.$nextTick(()=>{this.scrollToActiveNegotiation();})
      }
    });
  },
  beforeDestroy() {
    this.unsubscribe();
  },
  methods: {
    isNegotiationActive(negotiation){
      // TODO: сделать проверку на даты возможно через пермишен или конфиг.
      //  Пока хардкод на дату начала, но конфиг поддерживает
      //  возможность забить на дату начала. нужно это проверять
      // if( (new Date()) < (new Date(this.stage.date_start)) ){
      //   return false;
      // }

      return this.stage.current_negotiation_id && this.stage.current_negotiation_id === negotiation.id;
    },


    onDrop(dragResult) {
      if (dragResult.removedIndex === dragResult.addedIndex) {
        return;
      }

      let movedNegotiation = this.stage.negotiations[dragResult.removedIndex];
      let newPosition = dragResult.addedIndex + 1;
      this.$emit('negotiation-moved', movedNegotiation, newPosition);

      this.stage.negotiations = applyDragForDndrop(this.stage.negotiations, dragResult);
    },

    saveNegotiation(negotiation) {
      negotiation.stage_id = this.stage.id;
      negotiation.permissions = {
        [this.$permissions.CARD_STAGE_NEGOTIATION.MOVE]: {can: true, rulesPass: true, errors: []},
        [this.$permissions.CARD_STAGE_NEGOTIATION.DELETE]: {can: true, rulesPass: true, errors: []},
      }
      this.stage.negotiations.push(negotiation);
      this.$emit('negotiation-saved', negotiation);
    },

    deleteNegotiation(negotiation) {
      let index = this.stage.negotiations.indexOf(negotiation);
      this.stage.negotiations.splice(index, 1);
      // this.stage.negotiations = this.stage.negotiations.filter(n=>n.id!==negotiation.id);
      this.$emit('negotiation-deleted', negotiation);
    },


    scrollToActiveNegotiation(){
      let negotiatedWidth = 0;
      for( let i=0; i<this.stage.negotiations.length; i++ ){
        if( this.stage.negotiations[i].negotiated ){
          negotiatedWidth += this.$refs['negotiation_' + this.stage.type + '_' + (i)][0]?.$el?.clientWidth;
          continue;
        }
        negotiatedWidth -= this.$refs['negotiation_' + this.stage.type + '_' + (i)][0]?.$el?.clientWidth;
        break;
      }
      if( this.$refs.stage_ul ){
        this.$refs.stage_ul.$el.scrollTo({top: 0,left: negotiatedWidth,behavior: 'auto'});
      }
    },
  }
}
</script>


<style scoped lang="scss">
@import "./src/assets/css/project-variables";


.card-stage-wrapper {
  //border: 1px dashed $--color-primary;
  //border-radius: $--border-radius-base;
  //padding: 10px 20px;
  //
  //&.active {
  //  border: 3px solid $--color-primary;
  //}
}

.card-stage-header {
  text-align: center;
  padding-bottom: 5px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 2rem;

  .card-stage-header-name {
    font-weight: bold;
  }
}

.card-stage-divider {
  margin:5px;
}




.timeline {
  list-style-type: none;

  padding:0;
  margin: 0;
  display: flex;
  overflow: auto;
  justify-content: flex-start;
  align-items: flex-start;

  &.oneNegotiation{
    justify-content: center;
  }


  .negotiation {
    padding: 25px 0 10px 0;
    min-width: 150px;
    position: relative;
    &:hover{
      border-radius: $--border-radius-base;
      box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);

      .negotiation-bottom {
        border-top: 2px solid #ffffff !important;

        &-icon, &:before {
          display: none !important;
        }
      }

      .negotiation-actions{
        display: flex;
        justify-content: space-around;
      }
    }

    //&:first-child .negotiation-bottom:before{
    //  left:5%;
    //}
    //&:last-child .negotiation-bottom:before{
    //  left:unset;
    //  right:5%;
    //}

    &.complete {
      .negotiation-bottom {
        border-top: 2px solid $--color-success;
      }
      .negotiation-bottom:before {
        background-color: $--color-success;
      }

      .negotiation-bottom-icon {
        color: white;
      }
    }
    &.active {
      .negotiation-bottom:before {
        background-color: $--color-primary;
      }
      .negotiation-bottom-icon {
        color: white;
      }
    }

    &-top {
      margin-bottom: 20px;
      padding: 0px 40px;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-weight: 100;
    }

    &-bottom{
      padding: 20px 5px 0 5px;
      text-align: center;
      position: relative;
      border-top: 2px solid #adadad;

      &-type{
        position: relative;
        z-index: 1;
        font-weight: bold;
      }

      &-icon {
        position: absolute;
        top: -13px;
        left: calc(50% - 9px);
        font-size: 20px;
        color: #eee;
      }

      &:before {
        content: '';
        width: 53px;
        height: 53px;
        background-color: white;
        position: absolute;
        top: -28px;
        left: calc(50% - 25px);
        transition: all 200ms ease-in;
        border: 10px solid #fff;
        border-radius: 50px;
        box-sizing: border-box;
      }

    }

    &-edit-button{
      font-size: 1.1rem;
      margin-top: -5px;
    }
    &-delete-button{
      color: $--color-danger;
      font-size: 1.1rem;
      margin-top: -5px;
    }

    .negotiation-actions {
      position: absolute;
      top:15px;
      z-index: 10;
      width: 100%;
      display: none;
      justify-content: space-around;
      font-size: 1.1rem;

      &-drag-handle{
        cursor: move;
      }
    }
  }
}





</style>