<template>
  <div v-if="count">
    <add-users-dialog
      title-text="Выбор профиля"
      single-select
      :search-method="$api.user.searchAvailableMeDeputies"
      add-button-text="Нажмите на строку для выбора"
      @users-selected="doDeputyLogin"
    >
      <el-tooltip content="Делегирование доступа" placement="top-start" slot="reference">
      <el-button
        type="text"
        class="table-action-button"
        icon="fas fa-user-friends"
      >
      </el-button>
      </el-tooltip>
    </add-users-dialog>
  </div>
</template>

<script>


import AddUsersDialog from "@/components/user/AddUsersDialog.vue";

export default {
  name: "deputy-modal",
  components: {AddUsersDialog},

  data() {
    return {
      loading: false,
      count: null,
    }
  },

  mounted() {
    this.searchAvailableMeDeputes();
  },

  methods: {
    searchAvailableMeDeputes(){
      this.$api.user.searchAvailableMeDeputies({
        count_only: 1
      })
        .then(data => {
          this.count = data.count;
        })
    },

    doDeputyLogin(user) {
      this.$api.user.loginAsDeputy({
        user_id: user.id
      })
        .then(data => {
          localStorage.setItem('jwt', data.token);
          window.location.reload();
        })
    },

  }

}
</script>

<style>

</style>