export default {
    "entities": [
        {
            "showName": "Пользователи",
            "reportKey": "users"
        },
        {
            "showName": "Карты",
            "reportKey": "cards"
        },
        {
            "showName": "Цели в карте",
            "reportKey": "card_elements"
        }
    ],
    "providers": {
        "array": "app\\components\\MassActions\\providers\\ArrayDataProvider",
        "report_builder": {
            "entityPreviewSettings": {
                "users": {
                    "relations": [
                        [
                            "users"
                        ],
                        [
                            "users",
                            "meta"
                        ]
                    ],
                    "selected_fields": [
                        {
                            "relationsChain": [
                                "users"
                            ],
                            "block": "users",
                            "field": "persnum",
                            "relationName": "Пользователи",
                            "name": "Перс. №"
                        },
                        {
                            "relationsChain": [
                                "users"
                            ],
                            "block": "users",
                            "field": "full_name",
                            "relationName": "Пользователи",
                            "name": "ФИО"
                        },
                        {
                            "relationsChain": [
                                "users"
                            ],
                            "block": "users",
                            "field": "email",
                            "relationName": "Пользователи",
                            "name": "Email"
                        },
                        {
                            "relationsChain": [
                                "users"
                            ],
                            "block": "users",
                            "field": "position",
                            "relationName": "Пользователи",
                            "name": "Должность"
                        },
                        {
                            "relationsChain": [
                                "users"
                            ],
                            "block": "users",
                            "field": "subdivision",
                            "relationName": "Пользователи",
                            "name": "Подразделение"
                        }
                    ]
                },
                "cards": {
                    "relations": [
                        [
                            "cards"
                        ],
                        [
                            "cards",
                            "info"
                        ]
                    ],
                    "selected_fields": [
                        {
                            "relationsChain": [
                                "cards"
                            ],
                            "block": "cards",
                            "field": "id",
                            "relationName": "Карты",
                            "name": "ID"
                        },
                        {
                            "relationsChain": [
                                "cards"
                            ],
                            "block": "cards",
                            "field": "year",
                            "relationName": "Карты",
                            "name": "Год"
                        },
                        {
                            "relationsChain": [
                                "cards"
                            ],
                            "block": "cards",
                            "field": "period",
                            "relationName": "Карты",
                            "name": "Период"
                        },
                        {
                            "relationsChain": [
                                "cards",
                                "user"
                            ],
                            "block": "users",
                            "field": "persnum",
                            "relationName": "Пользователь",
                            "name": "Перс. №"
                        },
                        {
                            "relationsChain": [
                                "cards",
                                "user"
                            ],
                            "block": "users",
                            "field": "full_name",
                            "relationName": "Пользователь",
                            "name": "ФИО"
                        },
                        {
                            "relationsChain": [
                                "cards",
                                "user"
                            ],
                            "block": "users",
                            "field": "position",
                            "relationName": "Пользователь",
                            "name": "Должность"
                        }
                    ]
                },
                "card_elements": {
                    "relations": [
                        [
                            "card_elements"
                        ],
                        [
                            "card_elements",
                            "section"
                        ],
                        [
                            "card_elements",
                            "section",
                            "card"
                        ]
                    ],
                    "selected_fields": [
                        {
                            "relationsChain": [
                                "card_elements",
                                "section",
                                "card"
                            ],
                            "block": "cards",
                            "field": "id",
                            "relationName": "Карта",
                            "name": "ID"
                        },
                        {
                            "relationsChain": [
                                "card_elements",
                                "section",
                                "card",
                                "user"
                            ],
                            "block": "users",
                            "field": "full_name",
                            "relationName": "Пользователь",
                            "name": "ФИО"
                        },
                        {
                            "relationsChain": [
                                "card_elements"
                            ],
                            "block": "card_elements",
                            "field": "id",
                            "relationName": "Цели",
                            "name": "ID"
                        },
                        {
                            "relationsChain": [
                                "card_elements",
                                "section"
                            ],
                            "block": "card_sections",
                            "field": "type",
                            "relationName": "Секция",
                            "name": "Тип"
                        },
                        {
                            "relationsChain": [
                                "card_elements"
                            ],
                            "block": "card_elements",
                            "field": "name",
                            "relationName": "Цели",
                            "name": "Название"
                        }
                    ]
                }
            }
        },
        "file": "app\\components\\MassActions\\providers\\FileDataProvider"
    },
    "actions": {
        "cards": {
            "set_stage": {
                "showName": "Запуск этапов карты целей",
                "template": "components/massActions/actionTemplates/card/CardStartStageAction"
            },
            "set_negotiation": {
                "showName": "Перевод карты на шаг",
                "template": "components/massActions/actionTemplates/card/CardGoToNegotiationAction"
            },
            "update_stage_negotiation_user": {
                "showName": "Изменение шага",
                "template": "components/massActions/actionTemplates/card/CardUpdateNegotiationAction"
            },
            "delete_stage_negotiation": {
                "showName": "Удаление шага",
                "template": "components/massActions/actionTemplates/card/CardDeleteNegotiationAction"
            },
            "create_element": {
                "showName": "Добавление цели в карты",
                "template": "componentsCompany/global/massActions/actionTemplates/card/CardAddElementAction"
            },
            "create_element_from_library": {
                "showName": "Добавление цели из библиотеки в карты",
                "template": "components/massActions/actionTemplates/card/CardAddElementFromLibraryAction"
            },
            "delete": {
                "showName": "Удаление карт",
                "template": "components/massActions/actionTemplates/card/CardDeleteAction"
            }
        },
        "users": {
            "create_card": {
                "showName": "Создать карту",
                "template": "components/massActions/actionTemplates/user/UserCreateCardAction"
            },
            "update_subordination": {
                "showName": "Изменить руководителя",
                "template": "components/massActions/actionTemplates/user/UserSetChiefAction",
                "templateData": {
                    "select_placeholder": "Выберите тип руководителя",
                    "select_button_text": "Выбрать руководителя"
                }
            },
            "clear_subordination": {
                "showName": "Удалить руководителя",
                "template": "components/massActions/actionTemplates/user/UserRemoveChiefAction",
                "templateData": {
                    "select_placeholder": "Выберите тип руководителя"
                }
            },
            "assign_role": {
                "showName": "Назначить роль",
                "template": "components/massActions/actionTemplates/user/UserAssignRoleAction"
            },
            "revoke_role": {
                "showName": "Изъять роль",
                "template": "components/massActions/actionTemplates/user/UserRevokeRoleAction"
            },
            "send_notification": {
                "showName": "Отправить уведомление",
                "template": "components/massActions/actionTemplates/user/UserSendNotificationAction"
            }
        },
        "card_elements": {
            "delete_element": {
                "showName": "Удаление цели",
                "template": "components/massActions/actionTemplates/cardElement/CardElementDeleteAction"
            },
            "create_comment": {
                "showName": "Создание комментария",
                "template": "components/massActions/actionTemplates/cardElement/CardElementCreateCommentAction"
            }
        }
    }
}