<template>

  <div>

    <el-row
      v-if="showEditModeButtons"
      type="flex"
      justify="space-between"
    >
      <h2 style="margin:0 0 10px 0">Конструктор отчетов</h2>
      <div>

        <save-report-dialog
          v-if="saveReportActions"
          :report="{
              entity_id: loadedReport.entity_id,
              access: loadedReport.access,
              name: loadedReport.name,
              elements: elements,
              selectedFields: selectedFields,
              relations: relations,
              searchFields: searchFields,
          }"
          @report-saved="$emit('report-saved', $event)"
          :loading.sync="loading"
        >
          <el-button
            v-show="isToShowSaveReportButton()"
            style="margin-left: 20px;"
            slot="reference"
            size="small"
            type="primary"
            plain
            icon="fas fa-save"
            :loading="loading"
          >
            Сохранить отчет
          </el-button>
        </save-report-dialog>
        <save-report-dialog
          style="margin-left: 20px;"
          v-if="saveReportActions && loadedReport && loadedReport.entity_id"
          :report="{
                        elements: elements,
                        selectedFields: selectedFields,
                        relations: relations,
                        searchFields: searchFields,
                    }"
          @report-saved="$emit('report-saved', $event)"
        >
          <el-button
            slot="reference"
            :disabled="!selectedFields.length"
            size="small"
            plain
            type="primary"
            icon="fas fa-copy"
          >
            Сохранить отчет как
          </el-button>
        </save-report-dialog>
        <el-button
          style="margin-left:20px"
          size="small"
          :icon="editMode ? 'fas fa-chevron-up' : 'fas fa-chevron-down'"
          @click="editMode = !editMode"
        >
          {{ editMode ? 'Свернуть': 'Развернуть' }}
        </el-button>

      </div>
    </el-row>

    <div class="builder-container">

      <!--            <div>-->
      <!--                <div v-for="selectedField in selectedFields">{{selectedField}}</div>-->
      <!--            </div>-->
      <div v-show="showSelectedFieldsArea && (editMode || (loadedReport && loadedReport.id))">
        <Container
          class="selected-field-container"
          style="margin-bottom:20px;"
          group-name="1"
          orientation="horizontal"
          remove-on-drop-out
          @drop="onDrop('selectedFields', $event)"
        >
          <div class="selected-field-container-hint" v-if="!selectedFields.length">
            <div style="font-size: 1.5rem">Зона колонок отчета</div>
            <div>Перетаскивайте сюда элементы из блоков ниже, чтобы они стали колонками в вашем отчете</div>
          </div>
          <Draggable
            v-for="selectedField in selectedFields"
            :key="selectedField.alias+selectedField.field+Math.floor(Math.random() * 99999)"
          >
            <div class="selected-field-item">
              <div class="selected-field-item-relation">{{ selectedField.relationName }}</div>
              <div class="selected-field-item-name">{{ selectedField.name }}</div>
            </div>
          </Draggable>
        </Container>
      </div>


      <!--            <div>-->
      <!--                <div v-for="relation in relations">{{relation}}</div>-->
      <!--            </div>-->
      <div v-show="editMode" class="selected-field-container" style="margin-bottom:20px;">
        <BuilderColumn
          :elements.sync="elements"
          :load-children="loadChildren"
          @node-check-changed="nodeCheckChanged"
          @add-field-from-node="addFieldFromNode"
        ></BuilderColumn>

        <div class="selected-field-container-hint" v-if="!relations.length">
          <div style="font-size: 1.5rem; padding-top:20px">Зона выбора элементов отчета</div>
          <div>Начните с блока, относительно которого хотите построить отчет.</div>
          <div>Нажимая на блок, ниже будут открываться элементы, которые можно использовать в качестве колонок отчета
          </div>
          <div>Нажимая на блок, справа будут появляться блоки, которые связаны с этим блоком</div>
        </div>
      </div>


<!--      <div>-->
<!--          <div v-for="searchField in searchFields">{{searchField}}</div>-->
<!--      </div>-->
      <div
        v-show="editMode || !!searchFields.length"
        style="position: relative"
      >
        <el-button
          style="position: absolute; top:5px; right:1px; z-index: 999"
          size="small"
          icon="fas fa-plus"
          @click="searchFields.push({nestedOr:true, searchFields:[]})"
        >
          ИЛИ
        </el-button>
        <Container
          class="selected-field-container"
          style="margin-bottom:20px;"
          group-name="1"
          orientation="horizontal"
          remove-on-drop-out
          @drop="onDrop('searchFields', $event)"
        >
          <div class="selected-field-container-hint" v-if="!searchFields.length">
            <div style="font-size: 1.5rem">Зона условий</div>
            <div>Перетаскивайте сюда элементы из блоков выше, чтобы добавить условия</div>
          </div>

          <Draggable
            v-for="(searchField, fieldIndex) in searchFields"
            :key="fieldIndex"
          >

            <div v-if="searchField.nestedOr">
              <div class="selected-field-nested-or">
                <div style="font-weight: bold; padding:5px;">ИЛИ</div>
                <Container
                  class="selected-field-container"
                  style="margin:5px;width:unset"
                  group-name="1"
                  orientation="horizontal"
                  remove-on-drop-out
                  @drop="onDrop('searchFields', $event, searchField)"
                >
                  <div
                    v-if="!searchField.searchFields.length"
                    class="selected-field-container-hint"
                    style="padding:10px"
                  >
<!--                    <div style="font-size: 1.5rem">Зона условий</div>-->
                    <div>Элементы, добавленные сюда, будут объединены в условии через ИЛИ</div>
                  </div>

                  <Draggable
                    v-for="(nestedSearchField, nestedFieldIndex) in searchField.searchFields"
                    :key="nestedFieldIndex"
                  >
                    <builder-selected-field-item
                      :search-field="nestedSearchField"
                    ></builder-selected-field-item>
                  </Draggable>


                </Container>
              </div>
            </div>

            <builder-selected-field-item
              v-if="!searchField.nestedOr"
              :search-field="searchField"
            ></builder-selected-field-item>

          </Draggable>
        </Container>
      </div>

    </div>


    <div v-show="showBuildReportButtons && (editMode || (loadedReport && loadedReport.id) || !!searchFields.length)">
      <el-row type="flex" justify="space-between">
        <el-button
          :disabled="!selectedFields.length"
          :loading="loadingFromParent"
          type="primary"
          @click="$emit('build-report', {selectedFields, relations, searchFields})"
        >
          Построить отчет
        </el-button>
        <el-button
          style="margin-left:35px"
          class="button-icon-green"
          type="text"
          icon="fas fa-file-excel"
          :disabled="!selectedFields.length"
          :loading="loadingFromParent"
          @click="$emit('export-report', {selectedFields, relations, searchFields})"
        >
          Экспорт в XLSX
        </el-button>
      </el-row>
    </div>
  </div>


</template>

<script>
import BuilderColumn from "./BuilderColumn";
import {Container, Draggable} from 'vue-dndrop';
import {applyDragForDndrop} from "@/utils/helpers";
import SaveReportDialog from "../SaveReportDialog";
import BuilderSelectedFieldItem from "@/components/report/builder/BuilderSelectedFieldItem.vue";

export default {
  name: "Builder",
  components: {BuilderSelectedFieldItem, SaveReportDialog, BuilderColumn, Container, Draggable},
  props: {
    elements: Array,

    selectedFields: Array,
    relations: Array,
    searchFields: Array,

    loadedReport: Object,

    loadChildren: Function,

    loadingFromParent:{ type: Boolean, default: false },


    clickNodeFieldToSelect:{ type: Boolean, default: true },
    saveReportActions:{ type: Boolean, default: true },
    showEditModeButtons:{ type: Boolean, default: true },
    showSelectedFieldsArea:{ type: Boolean, default: true },
    showBuildReportButtons:{ type: Boolean, default: true },
  },
  computed: {},

  data() {
    return {
      editMode: true,
	    loading: false,
    }
  },
  methods: {

    nodeCheckChanged(node) {
      let nodeRelationsChain = node.relationChain;

      if (node.checked) {
        this.relations.push(nodeRelationsChain);
      } else {
        // должны найти все релейшены, начинающиеся с текущего релейшена
        // Для этого преобразовываем релейшены в текст, чтобы сравнить через  startsWith()

        let nodeRelationsChainString = nodeRelationsChain.join(',');


        // let newRelations = ;
        this.$emit('update:relations', this.relations.filter(relation => {
          let relationString = relation.join(',');
          return !relationString.startsWith(nodeRelationsChainString);
        }))

        // Убираем селекты
        this.$emit('update:selectedFields', this.selectedFields.filter(field => {
          let relationString = field.relationsChain.join(',');
          return !relationString.startsWith(nodeRelationsChainString);
        }));
        // this.selectedFields = ;

        // Убираем поиск
        this.$emit('update:searchFields', this.searchFields.filter(field => {
          let relationString = field.relationsChain.join(',');
          return !relationString.startsWith(nodeRelationsChainString);
        }));
        // this.searchFields = ;

      }

      this.$emit('node-check-changed', node);
    },

    addFieldFromNode({node, field}) {
      if( !this.clickNodeFieldToSelect ){
        return;
      }
      this.selectedFields.push(this.makeSelectFieldObject({node, field}));
    },

    onDrop(object, dragResult, nestedObject=null) {
      // Когда в одной группе несколько контейнеров, то приходят пустые евенты от другого контейнера
      if (dragResult.addedIndex === null && dragResult.removedIndex === null) {
        return;
      }

      // Если не перемещение, а добавление нового элемента,
      // То он пришел из другого списка и нужно преобразовать данные
      if (dragResult.removedIndex === null) {
        dragResult.payload = this.makeSelectFieldObject(dragResult.payload);
      }

      if( object === 'selectedFields' ){
        let newObject = applyDragForDndrop(this[object], dragResult);
        this.$emit('update:' + object, newObject);
      } else if( object === 'searchFields' ){
        if( nestedObject ){
          nestedObject.searchFields = applyDragForDndrop(nestedObject.searchFields, dragResult);
          this.$emit('update:' + object, this[object]);
        } else {
          let newObject = applyDragForDndrop(this[object], dragResult);
          this.$emit('update:' + object, newObject);
        }
      }


    },

    makeSelectFieldObject({node, field}) {
      // для селекта пишу цепочку релейшенов, чтобы при выключении релейшена найти и удалить селекты
      let nodeRelationsChain = node.relationChain;

      // Есть филды, которые работают через релейшен, но блоки для них не создавались
      // Поэтому у них указана цепочка релейшенов в самом филде. Нужно ее добавить
      if (field.relations) {
        nodeRelationsChain = nodeRelationsChain.concat(field.relations)
      }

      return {
        relationsChain: nodeRelationsChain,
        alias: field.alias ? field.alias : node.relationAlias,
        block: node.key,
        relation: node.relation,
        relationName: node.relationName,
        field: field.key,
        name: field.name,
        fieldAlias: field.fieldAlias,
        enum: field.enum,
        withRelationChain: !!field.relations,

        condition: '=',
        search: '',
      };
    },

    isToShowSaveReportButton() {
      if (!this.selectedFields.length) {
        return false;
      }
      if (!this.loadedReport.id) {
        return true;
      }

      return this.$can('update-report', this.loadedReport);
    }


  }
}
</script>

<style lang="scss">
@import "node_modules/element-ui/packages/theme-chalk/src/common/var.scss";
@import "@/assets/css/project-variables";

.builder-container {
  width: 100%;

}

.builder-container-col {
  display: inline-block;
  /*border-left: 1px solid red;*/
}

.builder-container-row {
  /*border-bottom: 1px solid red;*/
}

.builder-container-row-name {
  min-width: 200px;
  display: inline-block;
  vertical-align: top;
  padding: 5px;
}

.builder-container .el-checkbox-button {
  width: 100%;
}

.builder-container .el-checkbox-button__inner {
  width: 100%;
}

.builder-container-row-fields {
  border: 1px solid $--color-primary;
  padding: 5px;
  background-color: white;
  //margin-bottom:10px;
}


.smooth-dnd-container.horizontal.selected-field-container {
  display: inline-block;
}

.smooth-dnd-container.selected-field-container, .selected-field-container {
  position: relative;
  border: 2px dashed #aaa;
  background-color: #f8f8f8;
  min-height: 90px !important;
  width: 100%;
  overflow: auto;
}

.selected-field-container-hint {
  display: inline-block;
  position: absolute;
  top: 40px;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #575656;
}

.builder-container .dndrop-container.horizontal {
  display: flex !important;
}

.selected-field-nested-or {
  cursor: move;
  text-align: center;
  border: 1px solid $--color-primary;
  margin: 5px;
  background-color: #c2c2c2;
  min-width: 250px;
}

.selected-field-item {
  cursor: move;
  text-align: center;
  border: 1px solid $--color-primary;
  margin: 5px;
  background-color: white;
}

.selected-field-item-relation {
  padding: 10px;
  background-color: $--color-primary;
  color: $--button-primary-font-color;
}

.selected-field-item-name {
  padding: 10px;
}

.node-element-drag-ghost {
  padding: 0 10px;
  border: 1px solid $--color-primary;
  background-color: white;
  display: inline-block;
}


.builder-container .el-checkbox-button__inner {
  padding-right: 5px;
}


</style>