export default {
    "export": {
        "library_folder": {
            "class": "app\\build\\global\\exportImport\\services\\FolderExportService",
            "permissions": []
        },
        "card": {
            "class": "app\\build\\global\\exportImport\\services\\CardExportService",
            "permissions": []
        }
    },
    "import": {
        "users": {
            "class": "app\\build\\global\\exportImport\\services\\UsersImportService",
            "permissions": [],
            "example": "UsersImport.xlsx"
        }
    }
}